@use 'src/theme/variables';

.footer {
  height: 100px;
}

.socialLink {
  &:hover {
    color: variables.$primary;
  }
}
