@import 'variables';
@import 'cards';
@import 'fonts';
@import '~bootstrap/scss/bootstrap';

html,
body,
#root {
  height: 100%;
}

body {
  background-color: $background-color;
}

.social-link {
  transition: all 0.2s;

  &:hover {
    color: $primary !important;
    transition: all 0.2s;
  }
}

ul {
  list-style-type: none;

  li:before {
    content: '';
    border-color: transparent $secondaryVariant;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1.5em;
    top: 1em;
    position: relative;
  }
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
  background-color: $dark;
  padding: $spacing-small $spacing-medium;
}
