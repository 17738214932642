@use 'src/theme/mixins';
@use 'src/theme/variables';

.contact {
  background-color: variables.$darkVariant;
}

.form {
  @include mixins.media-breakpoint-down(lg) {
    width: 90%;
  }

  @include mixins.media-breakpoint-up(lg) {
    width: 50%;
  }

  input[type='text'],
  input[type='email'],
  textarea,
  textarea:focus {
    background-color: variables.$dark;
    border: none;
    color: variables.$light;
  }
}
