@use 'src/theme/variables';

.navbar {
  border-bottom: 2px solid variables.$secondary;
  height: 60px;
}

.navLink {
  &:hover {
    cursor: pointer;
  }
}
