@font-face {
  font-family: 'Raleway Regular';
  src: url('../assets/fonts/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Bold';
  src: url('../assets/fonts/Raleway-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway SemiBold';
  src: url('../assets/fonts/Raleway-SemiBold.ttf') format('truetype');
}

$font-family-sans-serif: 'Raleway Regular', 'Helvetica Neue', 'Roboto', sans-serif;
$font-family-base: 'Raleway Regular', 'Helvetica Neue', 'Roboto', sans-serif;
$font-family-bold: 'Raleway SemiBold', 'Helvetica Neue', 'Roboto', sans-serif;

body {
  font-family: $font-family-base !important;
}

.family-normal {
  font-family: 'Raleway Regular', sans-serif;
}

.family-semi {
  font-family: 'Raleway SemiBold', sans-serif;
}

.family-bold {
  font-family: 'Raleway Bold', sans-serif;
}
