$primary: #e31b6d;
$secondary: #04c2c9;
$secondaryVariant: #00a1a7;
$dark: #1b242f;
$darkVariant: #252934;
$light: #f5f5f5;

$background-color: #ffffff;

$border-color-light: #d9d9d9;
$border-color: $border-color-light;
$border-color-dark: #707070;

$spacing-xsmall: 0.25rem;
$spacing-small: 0.5rem;
$spacing-medium: 1rem;
$spacing-large: 2rem;
$spacing-xlarge: 4rem;

$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// bootstrap spacing
$spacers: (
  0: 0,
  1: $spacing-small,
  2: $spacing-medium,
  3: $spacing-large,
  4: $spacing-xlarge,
);
